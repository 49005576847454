<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
			<template v-slot:cell(name)='data'>
				<router-link v-if='!["patientView", "patientActiveView"].includes(viewType)' :to="{ name: 'ViewPatient', params: {id: data.item.patient_id}}" class='badge'>
                    <h5>
					<i class='fa fa-user'></i>
                    {{ data.item.patient.name }}
                    </h5>
                </router-link>
				<br>
				<div class='badge text-wrap mt-1'
					:title='data.item.name + " - "+ data.item.status'
					:class="serviceStatusBadges[data.item.status]"
				>
					<i class='fa fa-stethoscope'></i>
					{{ data.item.service.name}}
				</div>
				<br/>
				<div class='mt-2'
					v-if='data.item.body_parts'
					
				>
					<div class='badge badge-light text-wrap ml-2' :class="serviceStatusBadges[data.item.status]" v-for='(bodyPart, index) in data.item.body_parts' :key='index'>
						{{ bodyPart }}
					</div>
				</div>
				<div>
					<div class='badge badge-light m-1'>
						<i class='fa fa-user-md'></i>
						Dr. {{ data.item.doctor.name }}
					</div>
				</div>
				<div class='m-1'>
					<div v-if='data.item.branch' class='badge badge-light'>
						{{ data.item.branch ? data.item.branch.name : '' }}
					</div>
				</div>
			</template>
			
			<template v-slot:cell(appointments)='data'>
				<div v-for='(appointment, index) in data.item.appointments' :key='index'>
					<div  v-if='appointment.status == "finished"' class='mt-1'>
						<div class='badge badge-success' v-if='appointment.status == "finished"'>
							<i class='fa fa-calendar'></i>
							{{ appointment.datetime | datetime}}
						</div> 
						<br>
						<div class='badge badge-success mt-1'>
							<i class='fa fa-check'></i>
							finished
						</div> 
					</div> 

					<div  v-if='appointment.status == "pending"' class='mt-1'>
						<div class='badge badge-light'>
							<i class='fa fa-calendar'></i>
							{{ appointment.datetime | datetime}}
						</div> 
						<br/>
						<div class='badge badge-light mt-1'>
							<i class='fa fa-spinner'></i>
							pending
						</div> 
					</div> 

					<div  v-if='appointment.status == "no show"' class='mt-1'>
						<div class='badge badge-light'>
							<i class='fa fa-calendar'></i>
							{{ appointment.datetime | datetime}}
						</div>
						<br/>
						<div class='badge badge-warning mt-1'>
							no show
						</div>
					</div> 

					<div  v-if='appointment.status == "cancelled"' class='mt-1'>
						<div class='badge badge-danger'>
							<i class='fa fa-calendar'></i>
							{{ appointment.datetime | datetime}}
						</div>
						<br/>
						<div class='badge badge-danger mt-1'>
							cancelled
						</div>
					</div> 
				</div> 
				<div v-if='data.item.required_appointments > 1'>
					<div  v-if='data.item.appointments_count < data.item.required_appointments' >
						<div class='badge badge-warning'>
							{{ data.item.available_appointments_count }} reserved
							/
							{{ data.item.required_appointments }} sessions
						</div>
						<button class='btn btn-success btn-xs m-1' @click="modal('patientServiceAppointmentForm', {id: data.item.id}, 'refresh')">Reserve Appointment</button>
					</div>
					<div v-else class='badge badge-success'>
						<i class='fa fa-check'></i>
						{{ data.item.required_appointments }} sessions
					</div>
				</div>
			</template>

            <template v-slot:cell(price)='data'>
                <h4>
                    <div class='badge badge-success'>
                        {{ data.item.final_price | currency }}
                    </div>    
                </h4>
                <div v-if='data.item.patient_insurance'>
                    <div class='badge badge-light'>
                        patient:
                        {{ data.item.patient_pay | currency }}
                    </div>
					<br/>
                    <div class='badge badge-light mt-1'>
                        insurance:
                        {{ data.item.insurance_pay | currency }}
                    </div>
                </div>
                <div v-if='data.item.discount || data.item.deduction' >
                    <div class='badge badge-primary mt-1'>
                        {{ data.item.price | currency }}
                    </div> 
					<br/>
                    <div class='badge badge-warning mt-1'>
                        Deduction:
                        {{ data.item.deduction | currency }}
                    </div>       
					<br/>
                    <div class='badge badge-warning mt-1'>
                        Discount:
                        {{ data.item.discount | currency }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(insurance)='data'>
                <div v-if='data.item.insurance_provider' class='badge badge-success'>
                    {{ data.item.insurance_provider.name }}
                </div>
				<br>
                <div v-if='data.item.insurance_approval' :title='data.item.insurance_approval.status'  class='badge text-wrap mt-2' :class='data.item.insurance_approval.status=="approved" ? "badge-success": "badge-warning"'>
                    Approval #{{ data.item.insurance_approval.id }}
                </div>
                <div v-if='data.item.invoice_id' :title='data.item.invoice_id'  class='badge badge-info text-wrap mt-2'>
                    Invoice #{{ data.item.invoice_id }}
					<span v-if="data.item.invoice.claimed_at">
						claimed at {{ data.item.invoice.claimed_at | date }}
					</span>
                </div>


				<br>
				<div v-if=' data.item.insurance_patient_services.length'>
					Parent
					<br>
					<div v-for='(insurancePatientService, index) in data.item.insurance_patient_services' :key='index' >
						<div class='badge text-wrap badge-light mt-2'>
							#{{ insurancePatientService.id }}
							- {{ insurancePatientService.name }}
						</div>
					</div>
				</div>

				<div v-if='data.item.insurance_children_patient_services.length'>
					for insurance
					<br>
					<div v-for='(insurancePatientService, index) in data.item.insurance_children_patient_services' :key='index' >
						<div class='badge text-wrap badge-info mt-2'>
							#{{ insurancePatientService.id }}
							- {{ insurancePatientService.name }}
						</div>
					</div>
				</div>

            </template>
			<template v-slot:cell(patient_external_services)='data'>                                   
				<div v-for='(externalService, index) in data.item.patient_external_services' :key='index'>
					<div :title='externalService.description'>
						<div class='badge badge-primary m-2'>
							{{ externalService.external_service.name }}
						</div>
						<div class='badge badge-primary m-2'>
							{{ externalService.external_service.type }}
						</div>
						
						<div>
							<div class='badge badge-primary m-2'>
								{{ externalService.vendor.name }}
							</div>

							<div class='badge badge-primary m-2'>
								{{ externalService.price | currency}}
							</div>

							<div v-if='externalService.status == "pending"' class='badge badge-light m-2'>
								{{ externalService.status }}
							</div>

							<div v-if='["requested", "received"].includes(externalService.status)' class='badge badge-warning m-2'>
								{{ externalService.status }}
							</div>

							<div v-if='externalService.status == "delivered"' class='badge badge-success m-2'>
								{{ externalService.status }}
							</div>

							<div v-if='externalService.status == "cancelled"' class='badge badge-danger m-2'>
								{{ externalService.status }}
							</div>
						</div>

					</div>
				</div>
            </template>
			<template v-slot:cell(transactions)='data'>                                   
				<div v-for='(transaction, index) in data.item.transactions' :key='index'>
					<div v-if='transaction.amount != 0'>
						<div>
							<div v-if="transaction.entity">
								{{ transaction.entity.name }}
							</div>

							<div v-if='transaction.amount_remaining > 0' class='badge badge-danger'>
								{{ transaction.amount_paid }} / 
								{{ transaction.amount }}
							</div>
							<div v-else class='badge badge-success'>
								<i class='fa fa-check'></i>
								{{ transaction.amount }}
							</div>
						</div>
						<div v-if='transaction.cash_payment_parts.length == 0'>
							<div class='badge badge-light'>No Payments</div>
						</div>
						<div v-else v-for='(cashPaymentPart, index2) in transaction.cash_payment_parts' :key='"b"+index2'>
							<div class='badge badge-success ml-1'>
								{{ cashPaymentPart.amount }}
							</div>
							<div class='badge badge-success ml-1'>
								{{ cashPaymentPart.cash_payment.type }}
							</div>
							<div class='badge badge-success ml-1'>
								{{ cashPaymentPart.cash_payment.payment_method }}
							</div>
							<div class='badge badge-success ml-1'>
								<i class='fa fa-calendar'></i>
								{{ cashPaymentPart.cash_payment.date }}
							</div>
							<div v-if='cashPaymentPart.cash_return_payment_id' class='badge badge-danger ml-1'>
								returned
							</div>
						</div>
					</div>
				</div>
            </template>
			<template v-slot:cell(actions)='data'>                                   

				<div v-if='data.item.status != "virtual insurance" && can("patient_services.edit")'>
					<div v-if='data.item.appointments_count < data.item.required_appointments'>
						<div class='badge badge-warning'>{{ data.item.required_appointments - data.item.appointments_count }} not reserved</div>
						<button class='btn btn-success btn-xs m-1' @click="modal('patientServiceAppointmentForm', {id: data.item.id}, 'refresh')">Reserve Appointment</button>
					</div>
					<div v-else>
						<button  class='btn btn-success btn-xs m-1' @click="modal('patientServiceAppointmentForm', {id: data.item.id}, 'refresh')">Add Session</button>
					</div>
                	<button class='btn btn-primary btn-xs m-1' @click='modal("patientExternalServiceForm", {patientServiceId: data.item.id}, "refresh")'>+ New External Service</button>
                	<button class='btn btn-info btn-xs m-1' v-if='data.item.patient_insurance' @click='modal("servicesMap", {patientId: data.item.patient_id, insurancePatientServices: [data.item]}, "refresh")'>+ New Insurance Service</button>
					<div v-if='viewType != "patientActiveView"'>
						<button class='btn btn-success btn-xs m-1' @click="modal('patientServiceView', {id: data.item.id})">View</button>
						<button v-if='can("patient_services.edit")' class='btn btn-success btn-xs m-1' @click="modal('patientServiceForm', {id: data.item.id}, 'refresh')">ُEdit</button>
						<button v-if='["pending", "virtual insurance"].includes(data.item.status) && can("patient_services.delete")' class='btn btn-danger btn-xs m-1' @click="confirmAction('deletePatientService', data.item, 'refresh')">x</button>
					</div>
				</div>
				<div v-if='data.item.status == "virtual insurance" && can("patient_services.edit")'>
					<button v-if='can("patient_services.edit")' class='btn btn-success btn-xs m-1' @click="modal('patientServiceForm', {id: data.item.id}, 'refresh')">ُEdit</button>
					<button v-if='["pending", "virtual insurance"].includes(data.item.status) && can("patient_services.delete")' class='btn btn-danger btn-xs m-1' @click="confirmAction('deletePatientService', data.item, 'refresh')">x</button>
				</div>
				<button class='btn btn-success btn-xs m-1' @click="modal('transactionsList', {filter: {patient_service_id: data.item.id}}, 'refresh')">View Transactions</button>

				<!-- <div v-b-modal.modal-1 class='btn btn-xxs btn-primary'>Request Service</div>
				<a href="" class='m-2 btn btn-xxs btn-primary'>Add Session</a> -->
			</template>
        </base-list>
    </div>
</template>

<script>
import BaseListMethods from './BaseListMethods';

export default {
	extends: BaseListMethods,
	props: ['viewType', 'reorderingStatus'],
    data: function () {
		return {
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
				'virtual insurance': 'badge-info',
			},
			statusClass: {
				'finished': 'badge-success',
				'cancelled': 'badge-danger',
				'pending': 'badge-light',
				'no show': 'badge-warning',
			},
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Service' },
                // { key: 'patient', label: 'Patient' },
                { key: 'appointments', label: 'Appointments' },
                { key: 'price', label: 'Patient Price' },
                { key: 'transactions', label: 'Transactions' },
                { key: 'insurance', label: 'Insurance' },
                { key: 'patient_external_services', label: 'External Services' },
                { key: 'invoice_date', label: 'Invoice Date' },
                { key: 'actions', label: 'Actions' },
			]
		};
	},
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('patient-services', filter, cancelToken);
		},
		deletePatientService(patientService) {
			return this.apiRequest(apiClient => apiClient.delete('patient-services/'+patientService.id)).then(() => patientService);
		},
	},
	watch: {
		viewType: {
			immediate: true,
			handler: function () {
				if (this.viewType == 'doctorView') {
					this.hideFields = ['doctor.name', 'transactions', 'price'];
				}
				if (this.viewType == 'patientView') {
					this.hideFields = ['patient'];
				}
				if (this.viewType == 'patientActiveView') {
					this.hideFields = ['patient', 'price', 'insurance', 'transactions'];
				}
			}
		}
	}
}
</script>